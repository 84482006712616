import React from 'react';
import {useTranslation} from 'react-i18next';
import {Button, Form, Input} from 'antd';

import AppAnimateGroup from '../../../@crema/core/AppAnimateGroup';
import AppPageMetadata from '../../../@crema/core/AppPageMetadata';
import {ReactComponent as Logo} from '../../../assets/icon/comingsoon.svg';

import '../index.style.less';

const ComingSoon = () => {
  const {t} = useTranslation();

  const onFinish = (values) => {
    console.log('Success:', values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <AppAnimateGroup type='bottom'>
      <AppPageMetadata title='Coming Soon' />
      <div className='error-container' key='coming_soon'>
        <div className='error-img-lg'>
          <Logo />
        </div>
        <div>
          <div className='error-content'>
            <h3>
              <h5>{t('int-coming-soon')}</h5>
            </h3>
            <div className='error-para'>
              <p className='mb-0'>
                <h5>{t('int-coming-soon-msg')}</h5>
              </p>
            </div>
          </div>
          <div className='error-form-coming'>
            <Form
              className='error-form'
              name='basic'
              initialValues={{remember: true}}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}>
              <Form.Item
                name='email'
                className='form-field'
                rules={[{required: true, message: t('int-plc-email')}]}>
                <Input placeholder={t('int-email-address')} />
              </Form.Item>

              <Button type='primary' className='error-btn' htmlType='submit'>
                <h5>{t('int-notify-me')}</h5>
              </Button>
            </Form>
          </div>
        </div>
      </div>
    </AppAnimateGroup>
  );
};

export default ComingSoon;
