import axios from 'axios';

export default axios.create({
  baseURL: 'http://g-axon.work/jwtauth/api',
  headers: {
    'Content-Type': 'application/json',
  },
});

const AuthAPI = axios.create({
  baseURL: process.env.REACT_APP_bp2_login_manager,
  headers: {
    'Content-Type': 'application/json',
  },
});

const UserManagerAPI = axios.create({
  baseURL: process.env.REACT_APP_bp2_user_manager,
  headers: {
    'Content-Type': 'application/json',
  },
});

const LocationManagerAPI = axios.create({
  baseURL: process.env.REACT_APP_bp2_location_manager,
  headers: {
    'Content-Type': 'application/json',
  },
});

const CampaignManagerAPI = axios.create({
  baseURL: process.env.REACT_APP_bp2_campaign_manager,
  headers: {
    'Content-Type': 'application/json',
  },
});

const CampaignManagerLocalAPI = axios.create({
  baseURL: process.env.REACT_APP_bp2_campaign_manager_local,
  headers: {
    'Content-Type': 'application/json',
  },
});

const UploadManagerAPI = axios.create({
  baseURL: process.env.REACT_APP_bp2_upload_manager,
  headers: {
    'Content-Type': 'application/json',
  },
});

const ReportManagerAPI = axios.create({
  baseURL: process.env.REACT_APP_bp2_report_manager,
  headers: {
    'Content-Type': 'application/json',
  },
});

const FieldForceManagerAPI = axios.create({
  baseURL: process.env.REACT_APP_bp2_user_manager,
  headers: {
    'Content-Type': 'application/json',
  },
});

const RoleManagerAPI = axios.create({
  baseURL: process.env.REACT_APP_bp2_role_manager,
  headers: {
    'Content-Type': 'application/json',
  },
});

const AgencyManagerAPI = axios.create({
  baseURL: process.env.REACT_APP_bp2_agency_manager,
  headers: {
    'Content-Type': 'application/json',
  },
});

const NotificationManagerAPI = axios.create({
  baseURL: process.env.REACT_APP_notification_manager,
  headers: {
    'Content-Type': 'application/json',
  },
});

const FaceMatchAPI = axios.create({
  baseURL: process.env.REACT_APP_ecrm_facial_recognition,
});

const OrganizationManagerAPI = axios.create({
  baseURL: process.env.REACT_APP_organization_manager,
  headers: {
    'Content-Type': 'application/json',
  },
});

const ClusterMappingAPI = axios.create({
  baseURL: process.env.REACT_APP_cluster_mapping,
  headers: {
    'Content-Type': 'application/json',
  },
});

const RetailManagerAPI = axios.create({
  baseURL: process.env.REACT_APP_retail_manager,
  headers: {
    'Content-Type': 'application/json',
  },
});

const ProductManagerAPI = axios.create({
  baseURL: process.env.REACT_APP_product_manager,
  headers: {
    'Content-Type': 'application/json',
  },
});

const AuditAiManagerAPI = axios.create({
  baseURL: process.env.REACT_APP_audit_ai_manager,
  headers: {
    'Content-Type': 'application/json',
  },
});

const AuditAiVerificationManagerAPI = axios.create({
  baseURL: process.env.REACT_APP_audit_ai_verification_manager,
  headers: {
    'Content-Type': 'application/json',
  },
});

export {
  AuthAPI,
  UserManagerAPI,
  LocationManagerAPI,
  CampaignManagerAPI,
  UploadManagerAPI,
  ReportManagerAPI,
  FieldForceManagerAPI,
  RoleManagerAPI,
  AgencyManagerAPI,
  FaceMatchAPI,
  CampaignManagerLocalAPI,
  NotificationManagerAPI,
  OrganizationManagerAPI,
  ClusterMappingAPI,
  RetailManagerAPI,
  ProductManagerAPI,
  AuditAiManagerAPI,
  AuditAiVerificationManagerAPI,
};
