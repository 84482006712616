import React, {useContext, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import PropTypes from 'prop-types';
import {Dropdown, Layout, Select} from 'antd';
import {useTranslation} from 'react-i18next';
import {FiMoreVertical} from 'react-icons/fi';
import {AiOutlineMenu} from 'react-icons/ai';

import AppLogo from '../components/AppLogo';
import AppNotifications from '../../AppNotifications';
import UserInfo from '../components/UserInfo';
import {AuthContext} from 'contexts/AuthContextProvider/authContext';
import {getItem} from 'utils/Helper';

import './index.style.less';

const {Header} = Layout;
const {Option} = Select;

const AppHeader = ({isCollapsed, onToggleSidebar}) => {
  const {logout} = useContext(AuthContext);
  const navigate = useNavigate();
  const {i18n, t} = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('i18nextLng') || 'en');
  }, []);

  const handleLanguageChange = (value) => {
    i18n.changeLanguage(value);
  };

  const navigateToRespectiveDetailsPage = () => {
    const userType = getItem(localStorage, 'user_type');

    if (userType === 'org_mgt') {
      navigate(
        `/user-manager/user-details/${getItem(localStorage, 'user_id')}`,
      );
    } else {
      navigate(`/ff-manager/ff-details/${getItem(localStorage, 'user_id')}`);
    }
  };

  const menuMobile = [
    {
      label: (
        <div onClick={navigateToRespectiveDetailsPage}>
          {t('int-my-profile')}
        </div>
      ),
      key: 'profile-title',
    },
    {
      label: <div onClick={logout}>{t('int-log-out')}</div>,
      key: 'logout',
    },
  ];

  const Languages = [
    {value: 'en', lang: 'English'},
    {value: 'bn', lang: 'বাংলা'},
    {value: 'zh_CN', lang: '中国人'},
    {value: 'ms', lang: 'Malay'},
  ];

  return (
    <Header className='app-standard-header'>
      <a className='trigger' onClick={() => onToggleSidebar(!isCollapsed)}>
        <AiOutlineMenu />
      </a>
      <AppLogo />
      <div style={{display: 'flex', alignItems: 'center'}}>
        <div>
          <AppNotifications />
        </div>
        <div>
          <Select
            defaultValue={
              localStorage.getItem('i18nextLng')?.split('-')[0] || 'en'
            }
            onChange={handleLanguageChange}>
            {Languages.map((lang) => (
              <Option key={lang?.value} value={lang?.value}>
                {lang?.lang}
              </Option>
            ))}
          </Select>
        </div>
        <div className='header-hidden-userinfo'>
          <UserInfo />
        </div>
      </div>
      <div className='app-standard-header-section-mobile'>
        <Dropdown
          menu={{items: menuMobile, className: 'user-profile'}}
          trigger={['click']}>
          <a className='ant-dropdown-link' onClick={(e) => e.preventDefault()}>
            <FiMoreVertical />
          </a>
        </Dropdown>
      </div>
    </Header>
  );
};

export default AppHeader;

AppHeader.propTypes = {
  onToggleSidebar: PropTypes.func,
  isCollapsed: PropTypes.bool,
};
