import React, {useContext} from 'react';
import {AppLoader} from '../index';
import PropTypes from 'prop-types';
import {AuthContext} from '../../contexts/AuthContextProvider/authContext';

const AuthRoutes = ({children}) => {
  const {authUser} = useContext(AuthContext);
  const {isLoading} = authUser;
  return isLoading ? <AppLoader /> : <>{children}</>;
};

export default AuthRoutes;

AuthRoutes.propTypes = {
  children: PropTypes.node.isRequired,
};
