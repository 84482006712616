import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {Button, Col, Modal, Spin, Tree, Typography} from 'antd';

import AppRowSimpleContainer from '@crema/core/AppRowContainer/AppRowSimpleContainer';
import AppIcon from 'components/AppComponents/AppIcon';
import AppImage from 'components/AppComponents/AppImage';
import AppListCard from 'components/AppComponents/AppListCard';
import AppListCardItem from 'components/AppComponents/AppListCardItem';
import AppPageHeader from 'components/AppComponents/AppPageHeader';
import AppPageWrapper from 'components/AppComponents/AppPageWrapper';
import NoDataCmp from 'components/AppComponents/NoDataCmp';
import DeleteForm from 'components/AgencyComponents/DeleteForm';
import {hasAccess} from 'utils/Helper';
import Dummy from '../../../assets/images/dummy.png';

import {
  viewAllAgencies,
  deleteAgency as deleteAgencyApi,
} from 'api/AgencyManager';

import './index.css';

const {Title} = Typography;

export const initialValue = {
  name: '',
  id: null,
  isVisible: false,
  disableDeleteBtn: true,
};

const AgencyList = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();

  const {data: agencyList, isLoading: isAgenciesLoading} = viewAllAgencies();
  const {mutate: mutateDeleteAgency, isLoading: isAgencyDeleting} =
    deleteAgencyApi();
  const [confirmPopUp, setConfirmPopUp] = useState(initialValue);

  return (
    <Spin spinning={isAgencyDeleting || isAgenciesLoading}>
      <Modal
        title={t('int-delete-modal')}
        open={confirmPopUp.isVisible}
        centered
        width={600}
        footer={null}
        closable={true}
        onCancel={() => {
          setConfirmPopUp(initialValue);
        }}
        okText={t('int-delete')}
        cancelText={t('int-cancel')}>
        <DeleteForm
          key={confirmPopUp.id}
          confirmPopUp={confirmPopUp}
          setConfirmPopUp={setConfirmPopUp}
          mutateDeleteAgency={mutateDeleteAgency}
        />
      </Modal>

      <AppPageHeader title={t('int-affiliates')}>
        {hasAccess(189) ? (
          <Button
            type='primary'
            style={{display: 'flex', alignItems: 'center', gap: '.5rem'}}
            icon={<AppIcon tag='akar-icons:plus' style={{color: '#fff'}} />}
            onClick={() => {
              navigate('/agency-manager/agency-create');
            }}>
            {t('int-add-new-affiliate')}
          </Button>
        ) : null}
      </AppPageHeader>
      <AppPageWrapper>
        <AppRowSimpleContainer>
          {agencyList?.length ? (
            agencyList?.map((agency) => (
              <Col xs={24} md={12} lg={12} key={agency.id}>
                <AppListCard
                  title={t('int-affiliate')}
                  extra={
                    <>
                      {hasAccess(190) && (
                        <AppIcon
                          tag='entypo:edit'
                          style={{
                            marginRight: '0.5rem',
                          }}
                          onClick={() =>
                            navigate(
                              '/agency-manager/agency-update/' + agency.id,
                            )
                          }
                        />
                      )}
                      {hasAccess(191) && (
                        <AppIcon
                          tag='mdi:delete'
                          onClick={() =>
                            setConfirmPopUp((value) => ({
                              ...value,
                              isVisible: true,
                              name: agency.name,
                              id: agency.id,
                            }))
                          }
                        />
                      )}
                    </>
                  }>
                  <AppRowSimpleContainer style={{padding: '10px 24px'}}>
                    <Col xs={8}>
                      <AppImage
                        src={
                          agency.image === null
                            ? Dummy
                            : `${process.env.REACT_APP_cdn}${agency.image}`
                        }
                        width='80'
                        alt='Agency'
                      />
                    </Col>
                    <Col xs={16}>
                      <Title level={3} type='secondary'>
                        {agency.name}
                      </Title>
                      <Title level={5} type='secondary'>
                        {agency.email}
                      </Title>
                      <Title level={5} type='secondary'>
                        {agency.phone_number}
                      </Title>
                    </Col>
                  </AppRowSimpleContainer>
                  <AppListCardItem
                    title={t('int-contact-person')}
                    value={agency.contact_person}
                  />
                  <AppListCardItem
                    title={t('int-address')}
                    value={agency.address}
                    color
                  />
                  <AppListCardItem title={t('int-tag')} value={agency.tag} />
                  <AppListCardItem
                    title={t('int-description')}
                    value={agency.description}
                    color
                  />
                  <AppListCardItem
                    title={t('int-resource-list')}
                    value={
                      <Tree
                        showLine
                        switcherIcon={
                          <AppIcon tag='material-symbols:arrow-forward-ios-rounded' />
                        }
                        treeData={agency.resources}
                      />
                    }
                  />
                </AppListCard>
              </Col>
            ))
          ) : (
            <NoDataCmp title={t('int-no-data-affiliate')} />
          )}
        </AppRowSimpleContainer>
      </AppPageWrapper>
    </Spin>
  );
};

export default AgencyList;
