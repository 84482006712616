import AccessProtected from 'lib/RouteProtection';
import {lazy} from 'react';

const ReportList = lazy(() => import('./CustomReport/ReportList'));
const QueryBuilder = lazy(() => import('./CustomReport/QueryBuilder'));
const CustomReport = lazy(() => import('./CustomReport/ViewCustomReport'));
const ModifyReport = lazy(() => import('./CustomReport/ModifyReport'));

const CreateDynamicReport = lazy(() =>
  import('./DynamicReport/CreateDynamicReport'),
);
const DynamicReportList = lazy(() =>
  import('./DynamicReport/DynamicReportList'),
);
const ReportDetails = lazy(() => import('./DynamicReport/ReportDetails'));

export const reportBaseUrl = `/dashboard/report`;

export const ReportManagerConfig = [
  {
    path: `${reportBaseUrl}/custom-report`,
    element: (
      <AccessProtected>
        <CustomReport />
      </AccessProtected>
    ),
  },
  {
    path: `${reportBaseUrl}/report-list`,
    element: (
      <AccessProtected>
        <ReportList />
      </AccessProtected>
    ),
  },
  {
    path: `${reportBaseUrl}/query-builder`,
    element: (
      <AccessProtected>
        <QueryBuilder />
      </AccessProtected>
    ),
  },
  {
    path: `${reportBaseUrl}/query-update/:id`,
    element: (
      <AccessProtected>
        <ModifyReport />
      </AccessProtected>
    ),
  },
  {
    path: '/dashboard/dynamic-report/report-create',
    element: <CreateDynamicReport />,
  },
  {
    path: '/dashboard/dynamic-report/report-list',
    element: <DynamicReportList />,
  },
  {
    path: '/dashboard/dynamic-report/report-details/:id',
    element: <ReportDetails />,
  },
];
