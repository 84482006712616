import {useState, useEffect} from 'react';
import {List, Button, Dropdown, Badge, Spin, Typography} from 'antd';
import {useTranslation} from 'react-i18next';

import AppScrollbar from '../AppScrollbar';
import NotificationItem from './NotificationItem';
import AppIcon from 'components/AppComponents/AppIcon';
import {useAuthContext} from 'contexts/AuthContextProvider/authContext';
import {
  getAllNotifications,
  viewAllNotifications,
} from 'api/NotificationManager';

import './index.style.less';

const AppNotifications = () => {
  const {t} = useTranslation();
  const {socket, authUser} = useAuthContext();

  const {data: oldNotifications, isLoading} = getAllNotifications(
    authUser.isAuthenticated,
  );
  const [notifications, setNotifications] = useState(oldNotifications || []);
  const {mutate: mutateViewAll, isLoading: isAllViewing} =
    viewAllNotifications();

  useEffect(() => {
    socket?.on('notification', (data) => {
      setNotifications((notifications) => {
        const notification = notifications.find((val) => val.id === data.id);
        if (notification) {
          return notifications;
        } else {
          return [data, ...notifications];
        }
      });
    });
  }, [socket]);

  const onViewAll = () => {
    const notSeen = [];

    for (let i = 0; i < notifications.length; i++) {
      if (notifications[i].seen === 0) notSeen.push(notifications[i].id);
    }
    mutateViewAll(notSeen, {
      onSuccess: () => {
        setNotifications((notifications) =>
          notifications.map((notification) => {
            notification.seen = 1;
            return notification;
          }),
        );
      },
    });
  };

  const items = [
    {
      label: (
        <Typography.Title level={5}>
          {t('int-notifications')} <span>({notifications.length})</span>
        </Typography.Title>
      ),
      key: 'notification-title',
      className: 'header',
    },
    {
      label: (
        <AppScrollbar className='notify-scroll-submenu'>
          <Spin spinning={isLoading || isAllViewing}>
            <List
              className='notify-list'
              dataSource={notifications}
              renderItem={(item) => {
                return (
                  <NotificationItem
                    key={item.id}
                    item={item}
                    setNotifications={setNotifications}
                  />
                );
              }}
            />
          </Spin>
        </AppScrollbar>
      ),
      key: 'notification-items',
    },
    {
      label: (
        <Button type='primary' className='notify-btn-all' onClick={onViewAll}>
          <h5 style={{color: 'white'}}>{t('int-view-all')}</h5>
        </Button>
      ),
      key: 'view-all-btn',
    },
  ];

  return (
    <Dropdown
      menu={{items: items, className: 'notify-header-message'}}
      className='dropdown'
      trigger={['click']}>
      <a className='notify-link' onClick={(e) => e.preventDefault()}>
        <span className='notify-icon'>
          <Badge
            count={notifications?.filter((noti) => noti.seen == 0)?.length}>
            <AppIcon tag='ic:baseline-notifications-active' />
          </Badge>
        </span>
      </a>
    </Dropdown>
  );
};

export default AppNotifications;
