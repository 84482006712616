import {lazy} from 'react';

const AuditAILanding = lazy(() => import('./Landing'));
const AuditDashboard = lazy(() => import('./Dashboard'));
const VerifyAudio = lazy(() => import('./VerifyAudio'));
const ProjectList = lazy(() => import('./Projects/ProjectList'));
const ProjectCreate = lazy(() => import('./Projects/ProjectCreate'));
const ProjectDetails = lazy(() => import('./Projects/ProjectDetails'));
const ProjectUpdate = lazy(() => import('./Projects/ProjectUpdate'));
const CreateQuestions = lazy(() => import('./Projects/Questions'));

export const AuditAIConfig = [
  {
    path: '/audit-ai/landing',
    element: <AuditAILanding />,
  },
  {
    path: '/audit-ai/audit-dashboard',
    element: <AuditDashboard />,
  },
  {
    path: '/audit-ai/project/list',
    element: <ProjectList />,
  },
  {
    path: '/audit-ai/project/details/:id',
    element: <ProjectDetails />,
  },
  {
    path: '/audit-ai/project/update/:id',
    element: <ProjectUpdate />,
  },
  {
    path: '/audit-ai/project/create',
    element: <ProjectCreate />,
  },
  {
    path: '/audit-ai/verify-audio',
    element: <VerifyAudio />,
  },
  {
    path: '/audit-ai/question/:id',
    element: <CreateQuestions />,
  },
];
