import {Button, Form, Input, Typography} from 'antd';
import proptypes from 'prop-types';

import AppRowSimpleContainer from '@crema/core/AppRowContainer/AppRowSimpleContainer';
import {initialValue} from 'pages/AgencyManager/AgencyList';
import {useTranslation} from 'react-i18next';

const {Text} = Typography;

const DeleteForm = ({confirmPopUp, setConfirmPopUp, mutateDeleteAgency}) => {
  const [form] = Form.useForm();

  const {t} = useTranslation();

  const confirmDeleteCheck = (value) => {
    if (value === confirmPopUp.name) {
      setConfirmPopUp((value) => ({...value, disableDeleteBtn: false}));
    } else {
      setConfirmPopUp((value) => ({...value, disableDeleteBtn: true}));
    }
  };

  const onAgencyDelete = () => {
    mutateDeleteAgency(confirmPopUp.id);
    setConfirmPopUp(initialValue);
  };

  return (
    <Form form={form} layout='vertical' onFinish={onAgencyDelete}>
      {localStorage.getItem('i18nextLng')?.includes('en') ? (
        <div style={{marginBottom: '1rem'}}>
          Please type <Text strong>{confirmPopUp.name}</Text> to confirm
        </div>
      ) : (
        <div style={{marginBottom: '1rem'}}>
          নিশ্চিত করতে <Text strong>{confirmPopUp.name}</Text> লিখুন
        </div>
      )}

      <Form.Item name='agency_name'>
        <Input
          name='agency_name'
          onChange={(e) => confirmDeleteCheck(e.target.value)}
        />
      </Form.Item>
      <AppRowSimpleContainer justify='center'>
        <Button
          type='secondary'
          htmlType='submit'
          style={
            confirmPopUp.disableDeleteBtn
              ? {background: '#D1CFCF', color: '#000', border: '#D1CFCF'}
              : {background: '#F80B39', color: '#FFF', border: '#F80B39'}
          }
          disabled={confirmPopUp.disableDeleteBtn}>
          {t('int-delete-modal-confirm')}
        </Button>
      </AppRowSimpleContainer>
    </Form>
  );
};

DeleteForm.propTypes = {
  confirmPopUp: proptypes.object,
  setConfirmPopUp: proptypes.func,
  mutateDeleteAgency: proptypes.func,
};

export default DeleteForm;
