import {string} from 'prop-types';
import {Typography} from 'antd';

import {AppRowContainer} from '@crema';
import NoDataImg from '../../assets/images/NoDataImg.svg';
import AppPageWrapper from './AppPageWrapper';

const {Title} = Typography;

const NoDataCmp = ({title}) => {
  return (
    <AppPageWrapper>
      <AppRowContainer
        style={{
          margin: '4rem',
          height: '30rem',
          display: 'flex',
          flexDirection: 'column',
          textAlign: 'center',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <img
          src={NoDataImg}
          width='150rem'
          height='auto'
          alt='Image'
          style={{paddingBottom: '1rem'}}
        />
        <div style={{paddingBottom: '1rem'}}>
          <Title level={5}>{title}</Title>
        </div>
      </AppRowContainer>
    </AppPageWrapper>
  );
};
NoDataCmp.propTypes = {
  title: string,
};

export default NoDataCmp;
