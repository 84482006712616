import AccessProtected from 'lib/RouteProtection';
import {lazy} from 'react';

const CategoryList = lazy(() => import('./CategoryList'));
const CategoryCreate = lazy(() => import('./CategoryCreate'));
const CategoryUpdate = lazy(() => import('./CategoryUpdate'));
const AddChildCategory = lazy(() => import('./AddChildCategory'));

export const categoryManagerConfig = [
  {
    path: '/organization-manager/category-list',
    element: (
      <AccessProtected>
        <CategoryList />
      </AccessProtected>
    ),
  },
  {
    path: '/organization-manager/category-create',
    element: (
      <AccessProtected>
        <CategoryCreate />
      </AccessProtected>
    ),
  },
  {
    path: '/organization-manager/category-update/:id',
    element: (
      <AccessProtected>
        <CategoryUpdate />
      </AccessProtected>
    ),
  },
  {
    path: '/organization-manager/add-children-category',
    element: (
      <AccessProtected>
        <AddChildCategory />
      </AccessProtected>
    ),
  },
];
