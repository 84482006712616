import PropTypes from 'prop-types';
import {bool} from 'prop-types';

const AppLabel = ({children, required}) => {
  return (
    <span className={required ? 'required' : ''} style={{fontWeight: 500}}>
      {children}
    </span>
  );
};

AppLabel.propTypes = {
  children: PropTypes.node,
  required: bool,
};
export default AppLabel;
