import AccessProtected from 'lib/RouteProtection';
import {lazy} from 'react';

export const materialManagerUrl = '/material-manager';
export const materialListUrl = `${materialManagerUrl}/material-list`;
export const addMaterialUrl = `${materialManagerUrl}/material-create`;
export const updateMaterialUrl = `${materialManagerUrl}/material-update`;
export const assignMaterialUrl = `${materialManagerUrl}/assign-material`;

const MaterialList = lazy(() => import('./MaterialList'));
const AddMaterial = lazy(() => import('./AddMaterial'));
const UpdateMaterial = lazy(() => import('./UpdateMaterial'));
const AssignMaterial = lazy(() => import('./AssignMaterial'));

export const materialManagerConfig = [
  {
    path: materialListUrl,
    element: (
      <AccessProtected>
        <MaterialList />
      </AccessProtected>
    ),
  },
  {
    path: addMaterialUrl,
    element: (
      <AccessProtected>
        <AddMaterial />
      </AccessProtected>
    ),
  },
  {
    path: `${updateMaterialUrl}/:id`,
    element: (
      <AccessProtected>
        <UpdateMaterial />
      </AccessProtected>
    ),
  },
  {
    path: `${assignMaterialUrl}/:id`,
    element: (
      <AccessProtected>
        <AssignMaterial />
      </AccessProtected>
    ),
  },
];
