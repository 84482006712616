import {Navigate} from 'react-router-dom';

import Notification from './Notification';

const ErrorHandler = (error) => {
  if (!error?.response?.data?.message) {
    return Notification('Something went wrong! Please try again', 'error');
  }

  if (error?.response?.data?.message === 'Session Expired') {
    localStorage.clear();
    <Navigate to='/login' />;
  } else {
    Notification(error?.response?.data?.message, 'error');
  }
};

export default ErrorHandler;
