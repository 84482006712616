import {getItem} from 'utils/Helper';
import {isValidToken} from './Jwt';

const isAuthenticated = () => {
  const authToken = getItem(localStorage, 'token');

  if (isValidToken(authToken)) {
    return true;
  } else {
    return false;
  }
};

export default isAuthenticated;
