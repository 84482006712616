import AccessProtected from 'lib/RouteProtection';
import {lazy} from 'react';

const FFLiveLocationReport = lazy(() => import('./FFLiveLocationReport'));
const FFContactLocationReport = lazy(() => import('./FFContactLocationReport'));
const ContactLocationReport = lazy(() => import('./ContactLiveLocationReport'));
const AttendanceSummaryReport = lazy(() =>
  import('./AttendanceReport/AttendanceSummaryReport'),
);
const AttendanceDetailsReport = lazy(() =>
  import('./AttendanceReport/AttendanceDetailsReport'),
);
const FFMarketVisitLocationReport = lazy(() =>
  import('./FFMarketVisitLocationReport'),
);

export const LiveLocationManagerConfig = [
  {
    path: '/dashboard/ff-live-location-report',
    element: (
      <AccessProtected>
        <FFLiveLocationReport />
      </AccessProtected>
    ),
  },
  {
    path: '/dashboard/ff-contact-location-report',
    element: (
      <AccessProtected>
        <FFContactLocationReport />
      </AccessProtected>
    ),
  },
  {
    path: '/dashboard/contact-live-location-report',
    element: (
      <AccessProtected>
        <ContactLocationReport />
      </AccessProtected>
    ),
  },
  {
    path: '/dashboard/attendance-summary-report',
    element: (
      <AccessProtected>
        <AttendanceSummaryReport />
      </AccessProtected>
    ),
  },
  {
    path: '/dashboard/attendance-detail-report/:id',
    element: (
      <AccessProtected>
        <AttendanceDetailsReport />
      </AccessProtected>
    ),
  },
  {
    path: '/dashboard/ff-market-visit-location-report',
    element: (
      <AccessProtected>
        <FFMarketVisitLocationReport />
      </AccessProtected>
    ),
  },
];
