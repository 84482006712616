import React from 'react';
import AppIcon from 'components/AppComponents/AppIcon';
import {hasAccess} from 'utils/Helper';

const routeItems = [
  {
    id: 'dashboard',
    title: 'Home',
    icon: <AppIcon tag='ant-design:home-filled' />,
    path: '/dashboard/home',
    access_code: 35, //dummy
  },
  {
    id: 'user-manager',
    title: 'Users',
    icon: <AppIcon tag='fa-solid:users' />,
    path: '/user-manager/user-list',
    access_code: 35,
  },
  {
    id: 'role-manager',
    title: 'Role',
    icon: <AppIcon tag='bxs:user-check' />,
    path: '/role-manager/role-list',
    access_code: 36,
  },
  {
    id: 'product-manager',
    title: 'Product',
    icon: <AppIcon tag='fa6-solid:box-open' />,
    path: '/product-manager/product-list',
    access_code: 105,
  },
  {
    id: 'campaign-manager',
    title: 'Campaign',
    icon: <AppIcon tag='material-symbols:campaign' />,
    path: '/campaign-manager/campaign-list',
    access_code: 37,
  },

  {
    id: 'notification-manager',
    title: 'Notification',
    icon: <AppIcon tag='ri:user-settings-fill' />,
    path: '/notification-manager/notification-list',
    access_code: 39,
  },
  {
    id: 'organization-manager',
    title: 'Organization',
    icon: <AppIcon tag='ci:settings-filled' />,
    path: '/organization-manager/settings',
    access_code: 38,
  },
  {
    id: 'ff-manager',
    title: 'FF Manager',
    icon: <AppIcon tag='fa-solid:users' />,
    path: '/ff-manager/ff-list',
    access_code: 105,
  },
];
// routeItems.map((el) => console.log(el.access_code));
let hasAccessRoutes = [];
routeItems.map((el) => hasAccess(el?.access_code) && hasAccessRoutes.push(el));
// console.log(hasAccessRoutes);

const routesConfig = [
  {
    id: 'dash',
    title: '',
    type: 'group',
    children: hasAccessRoutes,
  },
];
export default routesConfig;
