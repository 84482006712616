import {useCallback, useEffect, useState} from 'react';
import {useQueryClient} from 'react-query';
import io from 'socket.io-client';

import {isValidToken} from 'utils/Auth/Jwt';
import {getItem} from 'utils/Helper';

const color = 'color: #00FF00';

const useSocket = () => {
  const queryClient = useQueryClient();
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    if (isValidToken(getItem(localStorage, 'token'))) {
      connect();
    }
  }, []);

  const connect = useCallback(() => {
    // const socketOption = {
    //   extraHeaders: {
    //     Authorization: getItem(localStorage, 'token'),
    //     device_id: getDeviceInfo(),
    //   },
    // };

    // if (!process.env.REACT_APP_socket_url.includes('http://127.0.0.1')) {
    //   socketOption.path = '/notification-manager/socket.io';
    // }

    // const socket = io(process.env.REACT_APP_socket_url, socketOption);

    // socket.on('connect', () => {
    //   console.log('%cSocket connected', color);
    // });

    const socketOption = {
      transports: ['websocket', 'polling'],
    };

    if (!process.env.REACT_APP_socket_url.includes('http://127.0.0.1')) {
      socketOption.path = '/notification-manager/socket.io';
    }

    const socket = io(process.env.REACT_APP_socket_url, socketOption);

    socket.on('connect', () => {
      console.log('%cSocket connected', color);
      socket.emit('register', {
        jwt: getItem(localStorage, 'token'),
      });
    });

    socket.on('registered', (data) => {
      queryClient.invalidateQueries('get-online-active-users');
      console.log('%cSocket registered: ', color, data);
    });

    socket.on('disconnect', () => {
      console.log('%cSocket disconnected from server', color);
    });

    socket.on('error', (error) => {
      console.log('Socket Error:', error);
    });

    socket.on('exception', (error) => {
      console.log(error);
    });

    setSocket(socket);
  }, []);

  const disconSocket = () =>
    socket.emit('logout', {jwt: getItem(localStorage, 'token')});

  return {
    socket,
    connect,
    disconSocket,
  };
};

export default useSocket;
