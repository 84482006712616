import {lazy} from 'react';
import AccessProtected from 'lib/RouteProtection';

const OrganizationList = lazy(() => import('./OrganizationList'));
const ThemeSetup = lazy(() => import('./ThemeSetup'));
const ApkList = lazy(() => import('./ApkDistribution/ApkList'));
const CreateApk = lazy(() => import('./ApkDistribution/CreateApk'));
const OrganizationSettings = lazy(() => import('./Settings'));

export const organizationManagerConfig = [
  {
    path: '/organization-manager/settings',
    element: (
      <AccessProtected>
        <OrganizationList />
      </AccessProtected>
    ),
  },
  {
    path: '/organization-manager/configuration',
    element: (
      <AccessProtected>
        <OrganizationSettings />
      </AccessProtected>
    ),
  },
  {
    path: '/organization-manager/theme-setup',
    element: (
      <AccessProtected>
        <ThemeSetup />
      </AccessProtected>
    ),
  },
  {
    path: '/organization-manager/apk-list',
    element: (
      <AccessProtected>
        <ApkList />
      </AccessProtected>
    ),
  },
  {
    path: '/organization-manager/create-apk',
    element: (
      <AccessProtected>
        <CreateApk />
      </AccessProtected>
    ),
  },
];
