import {useQuery, useQueryClient, useMutation} from 'react-query';
import {useNavigate} from 'react-router-dom';

import {AgencyManagerAPI} from 'utils/ApiConfig';
import ErrorHandler from 'utils/ErrorHandler';
import {getItem} from 'utils/Helper';
import Notification from 'utils/Notification';

const queryOptions = {
  onError: ErrorHandler,
};

export const viewAllAgencies = () => {
  return useQuery(
    'agency-list',
    async () => {
      const result = await AgencyManagerAPI.get('/agency/view-all', {
        headers: {Authorization: `Bearer ${getItem(localStorage, 'token')}`},
      });
      return result?.data?.data;
    },
    queryOptions,
  );
};

export const getAgency = (id) => {
  return useQuery(
    ['agency', id],
    ({queryKey}) =>
      AgencyManagerAPI.get('/agency/view/' + queryKey[1], {
        headers: {Authorization: `Bearer ${getItem(localStorage, 'token')}`},
      }),
    {
      select: (data) => {
        return data.data.data;
      },
      ...queryOptions,
    },
  );
};

export const createNewAgency = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (body) =>
      AgencyManagerAPI.post('/agency/add', body, {
        headers: {Authorization: `Bearer ${getItem(localStorage, 'token')}`},
      }),
    {
      ...queryOptions,
      onSuccess: () => {
        queryClient.invalidateQueries('agency-list');
      },
    },
  );
};
export const updateAgency = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation(
    (body) =>
      AgencyManagerAPI.patch('/agency/update', body, {
        headers: {Authorization: `Bearer ${getItem(localStorage, 'token')}`},
      }),
    {
      ...queryOptions,
      onSuccess: () => {
        Notification('Agency Updated.', 'success');
        queryClient.invalidateQueries('agency-list');
        navigate('/agency-manager/agency-list');
      },
    },
  );
};

export const deleteAgency = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (id) =>
      AgencyManagerAPI.patch(
        '/agency/delete/' + id,
        {id},
        {
          headers: {Authorization: `Bearer ${getItem(localStorage, 'token')}`},
        },
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('agency-list');
        Notification('Agency deleted.', 'success');
      },
      ...queryOptions,
    },
  );
};
