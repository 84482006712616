import React from 'react';
import {useLocation} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import AppPageHeader from 'components/AppComponents/AppPageHeader';
import AppPageWrapper from 'components/AppComponents/AppPageWrapper';
import AppRowSimpleContainer from '@crema/core/AppRowContainer/AppRowSimpleContainer';

const DashboardCardView = () => {
  const {t} = useTranslation();

  const location = useLocation();

  const breadCrumbRoutes = [
    {
      link: '/dashboard/home',
      title: t('int-home'),
    },
    {
      link: '/dashboard/gds-dashboard',
      title: t('int-dashboard'),
    },
    {
      link: null,
      title: t('int-view'),
    },
  ];
  return (
    <>
      <AppPageHeader
        title={t('int-dashboard')}
        routeInfo={breadCrumbRoutes}></AppPageHeader>
      <AppPageWrapper>
        <AppRowSimpleContainer>
          <iframe
            style={{width: '100%', height: '100vh'}}
            src={location?.state?.link}
            title='description'
            allowFullScreen></iframe>
        </AppRowSimpleContainer>
      </AppPageWrapper>
    </>
  );
};

export default DashboardCardView;
