import AccessProtected from 'lib/RouteProtection';
import {lazy} from 'react';
import DashboardCardView from './GdsLinkView';

const DashboardCard = lazy(() => import('./DashboardCard'));
const DashboardList = lazy(() => import('./DashboardList'));
const CustomDashboard = lazy(() => import('./CustomDashboard'));
const UpdateDashboard = lazy(() => import('./UpdateDashboard'));

export const GdsdashboardManagerConfig = [
  {
    path: '/dashboard/gds-dashboard',
    element: (
      <AccessProtected>
        <DashboardCard />
      </AccessProtected>
    ),
  },
  {
    path: '/dashboard/gds-dashboard/view/:id',
    element: (
      <AccessProtected>
        <DashboardCardView />
      </AccessProtected>
    ),
  },
  {
    path: '/dashboard/gds-dashboard/dashboard-list',
    element: (
      <AccessProtected>
        <DashboardList />
      </AccessProtected>
    ),
  },
  {
    path: '/dashboard/gds-dashboard/custom-dashboard',
    element: (
      <AccessProtected>
        <CustomDashboard />
      </AccessProtected>
    ),
  },
  {
    path: '/dashboard/gds-dashboard/update/:id',
    element: (
      <AccessProtected>
        <UpdateDashboard />
      </AccessProtected>
    ),
  },
];
