import {Icon} from '@iconify/react';
import {string, object, func} from 'prop-types';

const AppIcon = ({tag, style, onClick, color = '#92979d', ...rest}) => {
  return (
    <Icon
      icon={tag}
      onClick={onClick}
      color={color}
      height='1.27rem'
      width='1.27rem'
      style={{
        cursor: 'pointer',
        ...style,
      }}
      {...rest}
    />
  );
};

AppIcon.propTypes = {
  tag: string,
  style: object,
  color: string,
  onClick: func,
};

export default AppIcon;
