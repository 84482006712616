import AccessProtected from 'lib/RouteProtection';
import {lazy} from 'react';

const HierarchyList = lazy(() => import('./HierarchyList'));
const HierarchyCreate = lazy(() => import('./HierarchyCreate'));
const HierarchyUpdate = lazy(() => import('./HierarchyUpdate'));

export const hierarchyManagerConfig = [
  {
    path: '/organization-manager/hierarchy-list',
    element: (
      <AccessProtected>
        <HierarchyList />
      </AccessProtected>
    ),
  },
  {
    path: '/organization-manager/hierarchy-create',
    element: (
      <AccessProtected>
        <HierarchyCreate />
      </AccessProtected>
    ),
  },
  {
    path: '/organization-manager/hierarchy-update/:id',
    element: (
      <AccessProtected>
        <HierarchyUpdate />
      </AccessProtected>
    ),
  },
];
