import AppAnimateGroup from '@crema/core/AppAnimateGroup';
import {node} from 'prop-types';
import './index.style.less';

const AppPageWrapper = ({children}) => {
  return (
    <div className='main-content-view'>
      <AppAnimateGroup
        type='bottom'
        height='auto'
        interval={100}
        duration={450}>
        {children}
      </AppAnimateGroup>
    </div>
  );
};

AppPageWrapper.propTypes = {
  children: node,
};

export default AppPageWrapper;
