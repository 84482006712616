import React, {useState, useContext, useEffect} from 'react';
import {Alert, Layout} from 'antd';
import {AppSuspense} from '../../index';
import {
  anonymousStructure,
  authorizedStructure,
  unAuthorizedStructure,
} from '../../../pages';
import AppErrorBoundary from '../AppErrorBoundary';
import generateRoutes from '../../utility/RouteGenerator';
import {Navigate, Route, Routes} from 'react-router-dom';
import {initialUrl} from '../../../shared/constants/AppConst';
import {AuthContext} from 'contexts/AuthContextProvider/authContext';

import './index.style.less';

const {Content} = Layout;

const AppContentView = () => {
  const {authUser} = useContext(AuthContext);
  const {isAuthenticated, user} = authUser;

  const [isOnline, setNetwork] = useState(window.navigator.onLine);

  const updateNetwork = () => {
    setNetwork(window.navigator.onLine);
  };

  useEffect(() => {
    window.addEventListener('offline', updateNetwork);
    window.addEventListener('online', updateNetwork);
    return () => {
      window.removeEventListener('offline', updateNetwork);
      window.removeEventListener('online', updateNetwork);
    };
  }, []);

  return (
    <>
      {!isOnline && (
        <Alert
          message='No Internet'
          description='You are offline! Please check your internet connection.'
          showIcon
          type='error'
          banner
        />
      )}

      <Content>
        <AppSuspense>
          <AppErrorBoundary>
            {generateRoutes({
              isAuthenticated: isAuthenticated,
              userRole: user?.role,
              unAuthorizedStructure,
              authorizedStructure,
              anonymousStructure,
            })}
            <Routes>
              <Route path='/' element={<Navigate to={initialUrl} replace />} />
            </Routes>
          </AppErrorBoundary>
        </AppSuspense>
      </Content>
    </>
  );
};

export default AppContentView;
