export const bdtFormat = (amount) => {
  return new Intl.NumberFormat('en-BD', {
    style: 'currency',
    currency: 'BDT',
  }).format(amount);
};

export const phpFormat = (amount) => {
  let bdt = new Intl.NumberFormat('en-PH', {
    style: 'currency',
    currency: 'PHP',
  });

  return bdt.format(amount);
};

export const formatNumber = (amount) => {
  return new Intl.NumberFormat('en-US').format(amount);
};

export const toTitleCase = (str) => {
  str = str.replace(/[_-]/g, ' '); // Remove underscores and dashes
  return str.toLowerCase().replace(/(?:^|\s)\w/g, function (match) {
    return match.toUpperCase();
  });
};

export function numberToWords(number) {
  const units = [
    '',
    'One',
    'Two',
    'Three',
    'Four',
    'Five',
    'Six',
    'Seven',
    'Eight',
    'Nine',
  ];
  const teens = [
    'Ten',
    'Eleven',
    'Twelve',
    'Thirteen',
    'Fourteen',
    'Fifteen',
    'Sixteen',
    'Seventeen',
    'Eighteen',
    'Nineteen',
  ];
  const tens = [
    '',
    '',
    'Twenty',
    'Thirty',
    'Forty',
    'Fifty',
    'Sixty',
    'Seventy',
    'Eighty',
    'Ninety',
  ];

  if (number === 0) {
    return 'Zero';
  }

  let words = '';

  if (number >= 1000000) {
    words += numberToWords(Math.floor(number / 1000000)) + ' Million ';
    number %= 1000000;
  }

  if (number >= 1000) {
    words += numberToWords(Math.floor(number / 1000)) + ' Thousand ';
    number %= 1000;
  }

  if (number >= 100) {
    words += numberToWords(Math.floor(number / 100)) + ' Hundred ';
    number %= 100;
  }

  if (number >= 20) {
    words += tens[Math.floor(number / 10)] + ' ';
    number %= 10;
  }

  if (number > 0 && number < 20) {
    if (number < 10) {
      words += units[number] + ' ';
    } else {
      words += teens[number - 10] + ' ';
    }
  }

  return words.trim();
}
