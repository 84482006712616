import AccessProtected from 'lib/RouteProtection';
import {lazy} from 'react';

const FFCreate = lazy(() => import('./FFCreate'));
const FFUpdate = lazy(() => import('./FFUpdate'));
const FFList = lazy(() => import('./FFList'));
const FFDetails = lazy(() => import('./FFDetails'));

export const ffManagerConfig = [
  {
    path: '/ff-manager/ff-create',
    element: (
      <AccessProtected>
        <FFCreate />
      </AccessProtected>
    ),
  },
  {
    path: '/ff-manager/ff-update/:param',
    element: (
      <AccessProtected>
        <FFUpdate />
      </AccessProtected>
    ),
  },
  {
    path: '/ff-manager/ff-list',
    element: (
      <AccessProtected>
        <FFList />
      </AccessProtected>
    ),
  },
  {
    path: '/ff-manager/ff-details/:param',
    element: (
      <AccessProtected>
        <FFDetails />
      </AccessProtected>
    ),
  },
];
