import {useMutation, useQuery} from 'react-query';
import {useNavigate} from 'react-router-dom';
import {AuthAPI, OrganizationManagerAPI} from 'utils/ApiConfig';

import ErrorHandler from 'utils/ErrorHandler';
import {getItem, removeAll} from 'utils/Helper';
import Notification from 'utils/Notification';

const queryOptions = {
  onError: ErrorHandler,
};

export const useLogin = () => {
  return useMutation(
    (body) => AuthAPI.post('/auth/signin-web', body),
    queryOptions,
  );
};

export const useFetchOrgTheme = () => {
  return useMutation(
    (id) =>
      OrganizationManagerAPI.get(`/org/theme/${id}`, {
        headers: {
          Authorization: `Bearer ${getItem(localStorage, 'token')}`,
        },
      }),
    queryOptions,
  );
};

export const useLogout = (enabled, setEnableLogout) => {
  const navigate = useNavigate();

  return useQuery(
    'logout',
    () =>
      AuthAPI.get('/auth/logout', {
        headers: {
          Authorization: `Bearer ${getItem(localStorage, 'token')}`,
        },
      }),
    {
      ...queryOptions,
      onSuccess: () => {
        removeAll(localStorage);
        navigate('/signin');
        setEnableLogout(false);
      },
      enabled,
    },
  );
};

export const useToggleSingleDeviceLogin = () =>
  useMutation(
    (status) =>
      OrganizationManagerAPI.patch(
        `/org/toggle-single-device-login`,
        {status},
        {
          headers: {
            Authorization: `Bearer ${getItem(localStorage, 'token')}`,
          },
        },
      ),
    {
      ...queryOptions,
      onSuccess: ({data: {message}}) => Notification(message, 'success'),
    },
  );
