import AccessProtected from 'lib/RouteProtection';
import {lazy} from 'react';

const ResourceList = lazy(() => import('./ResourceList'));
const ResourceCreate = lazy(() => import('./ResourceCreate'));
const ResourceUpdate = lazy(() => import('./ResourceUpdate'));

export const resourceManagerConfig = [
  {
    path: '/organization-manager/resource-list',
    element: (
      <AccessProtected>
        <ResourceList />
      </AccessProtected>
    ),
  },
  {
    path: '/organization-manager/resource-create',
    element: (
      <AccessProtected>
        <ResourceCreate />
      </AccessProtected>
    ),
  },
  {
    path: '/organization-manager/resource-update/:id',
    element: (
      <AccessProtected>
        <ResourceUpdate />
      </AccessProtected>
    ),
  },
];
