import AccessProtected from 'lib/RouteProtection';
import {lazy} from 'react';

const LocationList = lazy(() => import('./LocationList'));
const LocationCreate = lazy(() => import('./LocationCreate'));
const LocationUpdate = lazy(() => import('./LocationUpdate'));
const AddChildLocation = lazy(() => import('./AddChildLocation'));

export const locationManagerConfig = [
  {
    path: '/organization-manager/location-list',
    element: (
      <AccessProtected>
        <LocationList />
      </AccessProtected>
    ),
  },
  {
    path: '/organization-manager/location-create',
    element: (
      <AccessProtected>
        <LocationCreate />
      </AccessProtected>
    ),
  },
  {
    path: '/organization-manager/location-update/:id',
    element: (
      <AccessProtected>
        <LocationUpdate />
      </AccessProtected>
    ),
  },
  {
    path: '/organization-manager/add-children-location',
    element: (
      <AccessProtected>
        <AddChildLocation />
      </AccessProtected>
    ),
  },
];
