import {number, oneOfType, string} from 'prop-types';

const AppImage = ({src, alt, width = '150', height = 'auto'}) => {
  return (
    <img
      src={src}
      alt={alt}
      height={height}
      width={width}
      style={{borderRadius: '8px'}}
    />
  );
};

AppImage.propTypes = {
  src: string,
  alt: string,
  width: oneOfType([number, string]),
  height: oneOfType([number, string]),
};

export default AppImage;
