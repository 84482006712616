import {lazy} from 'react';
import AccessProtected from 'lib/RouteProtection';

const HRList = lazy(() => import('./HRList'));
const HRDetails = lazy(() => import('./HRDetails'));

export const hrManagerConfig = [
  {
    path: '/hr-manager/hr-letter',
    element: (
      <AccessProtected>
        <HRList />
      </AccessProtected>
    ),
  },
  {
    path: '/hr-manager/hr-details/:param',
    element: <HRDetails />,
  },
];
