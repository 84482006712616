import AccessProtected from 'lib/RouteProtection';
import {lazy} from 'react';

const UserCreate = lazy(() => import('./UserCreate'));
const UserUpdate = lazy(() => import('./UserUpdate'));
const UserList = lazy(() => import('./UserList'));
const UserDetails = lazy(() => import('./UserDetails'));

export const userManagerConfig = [
  {
    path: '/user-manager/user-create',
    element: (
      <AccessProtected>
        <UserCreate />
      </AccessProtected>
    ),
  },
  {
    path: '/user-manager/user-update/:param',
    element: (
      <AccessProtected>
        <UserUpdate />
      </AccessProtected>
    ),
  },
  {
    path: '/user-manager/user-list',
    element: (
      <AccessProtected>
        <UserList />
      </AccessProtected>
    ),
  },
  {
    path: '/user-manager/user-details/:param',
    element: (
      <AccessProtected>
        <UserDetails />
      </AccessProtected>
    ),
  },
];
