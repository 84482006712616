import {Col, Row, Typography} from 'antd';
import {bool, string, any} from 'prop-types';

const {Title} = Typography;

import './index.style.less';

const AppListCardItem = ({title, value, color, ...others}) => {
  return (
    <div className='custom-list-bg' {...others}>
      <Row style={{backgroundColor: `${color && 'rgba(3, 88, 140, 0.02)'}`}}>
        <Col xs={8} style={{marginBottom: 0}}>
          <Title level={5} style={{fontWeight: 'bold'}}>
            {title}
          </Title>
        </Col>
        <Col xs={16} style={{marginBottom: 0}}>
          <Title level={5} className='vl-title' style={{fontWeight: 'normal'}}>
            <div style={{marginTop: '-1.5rem', marginLeft: '1rem'}}>
              {value ? (
                <div style={{marginTop: '-1.2rem', marginLeft: '1rem'}}>
                  {value}
                </div>
              ) : (
                <div style={{marginLeft: '1rem'}}>{value}</div>
              )}
            </div>
          </Title>
        </Col>
      </Row>
    </div>
  );
};

AppListCardItem.propTypes = {
  title: string,
  value: any,
  color: bool,
};

export default AppListCardItem;
