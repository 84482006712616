import React from 'react';

const Signin = React.lazy(() => import('./Signin'));

const ForgotPassword = React.lazy(() => import('./ForgotPassword'));

const VerifyOtp = React.lazy(() => import('./VerifyOtp'));
const NewPassword = React.lazy(() => import('./NewPassword'));

export const authRouteConfig = [
  {
    path: '/signin',
    element: <Signin />,
  },
  {
    path: '/forget-password',
    element: <ForgotPassword />,
  },
  {
    path: '/verify-otp',
    element: <VerifyOtp />,
  },
  {
    path: '/reset-password',
    element: <NewPassword />,
  },
];
