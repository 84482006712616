import {useMutation, useQuery} from 'react-query';
import {AgencyManagerAPI, RoleManagerAPI} from 'utils/ApiConfig';
import ErrorHandler from 'utils/ErrorHandler';
import {getItem} from 'utils/Helper';
import Notification from 'utils/Notification';

let queryOptions = {
  retry: 1,
  onError: ErrorHandler,
};

export const getAgencies = () => {
  return useQuery(
    'agency-list',
    () =>
      AgencyManagerAPI.get('/agency/view-all', {
        headers: {
          Authorization: `Bearer ${getItem(localStorage, 'token')} `,
        },
      }),
    {
      ...queryOptions,
      select: (data) => data?.data?.data,
    },
  );
};

export const getPlatforms = () => {
  return useQuery(
    'platforms',
    () =>
      RoleManagerAPI.get('roles/platforms', {
        headers: {
          Authorization: `Bearer ${getItem(localStorage, 'token')} `,
        },
      }),
    {
      ...queryOptions,
      select: (data) => data?.data?.data,
    },
  );
};

export const getRoles = () => {
  return useQuery(
    'role-list',
    () =>
      RoleManagerAPI.get('roles/role-list', {
        headers: {
          Authorization: `Bearer ${getItem(localStorage, 'token')} `,
        },
      }),
    {
      select: (data) => data?.data?.data,
      ...queryOptions,
    },
  );
};

export const updateStatus = () => {
  return useMutation({
    mutationFn: (body) => {
      return RoleManagerAPI.patch('/roles/change-role-status', body, {
        headers: {
          Authorization: `Bearer ${getItem(localStorage, 'token')} `,
        },
      });
    },
    ...queryOptions,
  });
};

//REFACTOR:
export const createRole = () => {
  return useMutation({
    mutationFn: (body) => {
      return RoleManagerAPI.post('/roles/create-role', body, {
        headers: {
          Authorization: `Bearer ${getItem(localStorage, 'token')}`,
        },
      });
    },
    ...queryOptions,
    onSuccess() {
      Notification('Role Created.', 'success');
    },
  });
};

export const getRoleNames = () => {
  return useQuery(
    'access-list',
    () =>
      RoleManagerAPI.get('/roles/get-role-names', {
        headers: {
          Authorization: `Bearer ${getItem(localStorage, 'token')} `,
        },
      }),
    {...queryOptions, select: (data) => data?.data?.data},
  );
};

export const getResources = () => {
  return useQuery(
    'resource-list',
    () =>
      RoleManagerAPI.get('/roles/get-resource-map', {
        headers: {Authorization: `Bearer ${getItem(localStorage, 'token')}`},
      }),
    {
      ...queryOptions,
      select: (data) => data?.data?.data,
    },
  );
};

export const getRole = (roleId) => {
  return useQuery(
    ['role', roleId],
    ({queryKey}) =>
      RoleManagerAPI.get(`/roles/get-role/${queryKey[1]}`, {
        headers: {
          Authorization: `Bearer ${getItem(localStorage, 'token')} `,
        },
      }),
    {
      ...queryOptions,
      select: (data) => data?.data?.data,
    },
  );
};

export const updateRole = () => {
  return useMutation({
    mutationFn: (body) => {
      return RoleManagerAPI.patch('/roles/update-role', body, {
        headers: {
          Authorization: `Bearer ${getItem(localStorage, 'token')}`,
        },
      });
    },
    ...queryOptions,
    onSuccess() {
      Notification('Role Updated.', 'success');
    },
  });
};

export const deleteRole = () => {
  return useMutation({
    mutationFn: (id) => {
      return RoleManagerAPI.patch(
        `/roles/delete-role`,
        {id},
        {
          headers: {
            Authorization: `Bearer ${getItem(localStorage, 'token')}`,
          },
        },
      );
    },
    ...queryOptions,
    onSuccess() {
      Notification('Role Deleted.', 'success');
    },
  });
};

export const getRoleTypes = () =>
  useQuery(
    'role-types',
    () =>
      RoleManagerAPI.get('roles/role-types', {
        headers: {Authorization: `Bearer ${getItem(localStorage, 'token')}`},
      }),
    {
      ...queryOptions,
      select: (data) => data?.data?.data,
    },
  );

export const getRoleByAgency = (agency) =>
  useQuery(
    ['get-role-by-agency', agency],
    () =>
      RoleManagerAPI.get(`roles/roles-by-agency/${agency}`, {
        headers: {Authorization: `Bearer ${getItem(localStorage, 'token')}`},
      }),
    {
      ...queryOptions,
    },
  );

export const getReportToUsers = () =>
  useMutation(
    (body) =>
      RoleManagerAPI.post('/roles/ff-manager-list', body, {
        headers: {Authorization: `Bearer ${getItem(localStorage, 'token')}`},
      }),
    {
      ...queryOptions,
    },
  );
