import {AES, enc} from 'crypto-js';
import moment from 'moment';

export const setItem = (type, key, value) => {
  try {
    type.setItem(
      key,
      AES.encrypt(value, process.env.REACT_APP_SECRET).toString(),
    );
    return true;
  } catch (error) {
    console.error('setItem error:', error);
    return false;
  }
};

export const getItem = (type, key, defaultValue) => {
  try {
    const encryptedValue = type.getItem(key);
    if (!encryptedValue) {
      return defaultValue;
    }
    const decryptedValue = AES.decrypt(
      encryptedValue,
      process.env.REACT_APP_SECRET,
    ).toString(enc.Utf8);
    return decryptedValue;
  } catch (error) {
    console.error('getItem error:', error);
    return defaultValue;
  }
};

export const removeItem = (type, key) => {
  type.removeItem(key);
};

export const removeAll = (type) => {
  type.clear();
};

export const hasAccess = (accessNumber) => {
  return getItem(localStorage, 'resource_access')
    ?.split(',')
    ?.includes(accessNumber?.toString());
};

export const isColumnDateLessThanCurrentDate = (myDate) => {
  return moment(myDate).isBefore(moment().format('YYYY-MM-DD'));
};

export const getWeeksBetweenTwoDates = (startDate, endDate) => {
  startDate = moment(startDate);
  endDate = moment(endDate);
  const weeks = [];

  let currentWeek = moment(startDate).startOf('week');
  while (currentWeek <= endDate) {
    const endDateOfWeek = moment(currentWeek).endOf('week');

    weeks.push({
      id: crypto.randomUUID(),
      name: `${currentWeek.format('YYYY-MM-DD')} to ${endDateOfWeek.format(
        'YYYY-MM-DD',
      )}`,
      startDate: currentWeek.format('YYYY-MM-DD'),
      endDate: endDateOfWeek.format('YYYY-MM-DD'),
    });
    currentWeek = moment(currentWeek).add(1, 'week').startOf('week');
  }

  return weeks;
};

export const findWeekFromDate = (currentDate, weeks) => {
  currentDate = moment(currentDate);

  const currentWeekObj = weeks.find((week) => {
    return moment(currentDate).isBetween(
      moment(week.startDate),
      moment(week.endDate),
      null,
      '[]',
    );
  });

  return currentWeekObj;
};

export const isAlphaNumericValue = (value) => {
  return value && /^(?=.*[a-zA-Z])(?=.*[0-9]).+$/.test(value);
};

export const getHierarchyByTerminalPoint = (hierarchyTree, ffTerminalPoint) => {
  const hierarchy = [];

  const fn = (hierarchyTree, ffTerminalPoint) => {
    for (let i = 0; i < hierarchyTree.length; i++) {
      if (hierarchyTree[i].id >= ffTerminalPoint)
        hierarchy.push({
          id: hierarchyTree[i].id,
          name: hierarchyTree[i].name,
          parent: hierarchyTree[i].parent,
          parent_name: hierarchyTree[i].parent_name,
        });

      if (hierarchyTree[i].child?.length) {
        fn(hierarchyTree[i].child, ffTerminalPoint);
      }
    }
  };

  fn(hierarchyTree, ffTerminalPoint);

  return hierarchy;
};

export const getScheduleStatusFromCurrentDate = (fromDate, toDate) => {
  const fromDateObj = new Date(fromDate);
  const toDateObj = toDate ? new Date(toDate) : null;
  const currentObj = new Date();

  if (toDateObj && currentObj > toDateObj) {
    return 'Completed';
  } else if (currentObj < fromDateObj) {
    return 'Scheduled';
  } else {
    return 'Running';
  }
};

export const validateFileSize = (file) => {
  const fileSize = file.size / 1024;
  return fileSize <= 500;
};
