import AccessProtected from 'lib/RouteProtection';
import {lazy} from 'react';
import AgencyList from './AgencyList';
import UpdateAgency from './UpdateAgency';
const CreateAgency = lazy(() => import('./CreateAgency'));

export const AgencyManagerConfig = [
  {
    path: '/agency-manager/agency-list',
    element: (
      <AccessProtected>
        <AgencyList />
      </AccessProtected>
    ),
  },
  {
    path: '/agency-manager/agency-create',
    element: (
      <AccessProtected>
        <CreateAgency />
      </AccessProtected>
    ),
  },
  {
    path: '/agency-manager/agency-update/:id',
    element: (
      <AccessProtected>
        <UpdateAgency />
      </AccessProtected>
    ),
  },
];
