import React from 'react';
import {QueryClient, QueryClientProvider} from 'react-query';
import {ReactQueryDevtools} from 'react-query/devtools';

import './shared/styles/crema.less';
import {
  AppContextProvider,
  AppLayout,
  AppSuspense,
  AppThemeProvider,
  AuthRoutes,
} from './@crema';
import {AuthContextProvider} from './contexts/AuthContextProvider/authContext';
import {BrowserRouter} from 'react-router-dom';
import './@crema/services/index';
import SidebarContextProvider from 'contexts/AppContextProvider/SidebarContextProvider';
import {useDisableDevTool} from 'hooks/useDisableDevTool';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      suspense: true,
    },
  },
});

const App = () => {
  if (
    !(
      process.env.REACT_APP_STAGE === 'Development' ||
      process.env.REACT_APP_STAGE === 'Staging'
    )
  ) {
    useDisableDevTool();
  }
  return (
    <QueryClientProvider client={queryClient}>
      <AppSuspense>
        <AppContextProvider>
          <AppThemeProvider>
            <BrowserRouter>
              <AuthContextProvider>
                <SidebarContextProvider>
                  <AuthRoutes>
                    <AppLayout />
                  </AuthRoutes>
                </SidebarContextProvider>
              </AuthContextProvider>
            </BrowserRouter>
          </AppThemeProvider>
        </AppContextProvider>
      </AppSuspense>
      {process.env.REACT_APP_STAGE && <ReactQueryDevtools />}
    </QueryClientProvider>
  );
};

export default App;
