import React from 'react';
import {Input, Layout} from 'antd';
import './index.style.less';
import AppLogo from '../components/AppLogo';
import PropTypes from 'prop-types';
import {AiOutlineMenu} from 'react-icons/ai';
import {useTranslation} from 'react-i18next';

const AppHeader = ({showDrawer}) => {
  const {t} = useTranslation();
  const {Header} = Layout;
  const {Search} = Input;

  return (
    <Header className='app-BitBucket-header'>
      <a className='trigger' onClick={showDrawer}>
        <AiOutlineMenu />
      </a>
      <AppLogo />
      <Search
        className='bitBucket-header-search'
        placeholder={t('int-enter-search-text')}
      />
    </Header>
  );
};

export default AppHeader;

AppHeader.propTypes = {
  showDrawer: PropTypes.func,
};
