import {lazy} from 'react';

import AccessProtected from 'lib/RouteProtection';

const RoleCreate = lazy(() => import('./RoleCreate'));
const RoleUpdate = lazy(() => import('./RoleUpdate'));
const RoleList = lazy(() => import('./RoleList'));

export const roleManagerConfig = [
  {
    path: '/role-manager/role-create',
    element: (
      <AccessProtected>
        <RoleCreate />
      </AccessProtected>
    ),
  },
  {
    path: '/role-manager/role-update/:id',
    element: (
      <AccessProtected>
        <RoleUpdate />
      </AccessProtected>
    ),
  },
  {
    path: '/role-manager/role-list',
    element: (
      <AccessProtected>
        <RoleList />
      </AccessProtected>
    ),
  },
];
