import React from 'react';
import {Layout} from 'antd';
import './AppFooter.style.less';
import moment from 'moment';
import {useLayoutContext} from '../../../../contexts/AppContextProvider/LayoutContextProvider';

const {Footer} = Layout;

const AppFooter = () => {
  const {footer} = useLayoutContext();

  if (footer) {
    return (
      <Footer className='app-main-footer'>
        <p>
          Copyright{' '}
          <a href='https://v2.ltd' target='_blank' rel='noreferrer'>
            @V2 Technologies Ltd
          </a>
          &nbsp; {moment().format('YYYY')}
        </p>
        {/* <div className='footer-btn-view'>
          <Button type='link' className='footer-btn' color='primary'>
            Buy Now
          </Button>
        </div> */}
      </Footer>
    );
  }
  return null;
};

export default AppFooter;
