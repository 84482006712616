import AccessProtected from 'lib/RouteProtection';
import {lazy} from 'react';

const NotificationList = lazy(() => import('./NotificationList'));
const NotificationCreate = lazy(() => import('./NotificationCreate'));
const NotificationEdit = lazy(() => import('./NotificationEdit'));
const NotificationDetails = lazy(() => import('./NotificationDetails'));

export const NotificationManagerConfig = [
  {
    path: '/notification-manager/notification-list',
    element: (
      <AccessProtected>
        <NotificationList />
      </AccessProtected>
    ),
  },
  {
    path: '/notification-manager/create',
    element: (
      <AccessProtected>
        <NotificationCreate />
      </AccessProtected>
    ),
  },

  {
    path: '/notification-manager/notification-edit/:param',
    element: (
      <AccessProtected>
        <NotificationEdit />
      </AccessProtected>
    ),
  },
  {
    path: '/notification-manager/notification-details/:param',
    element: (
      <AccessProtected>
        <NotificationDetails />
      </AccessProtected>
    ),
  },
];
