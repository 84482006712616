import {lazy} from 'react';

const Dashboard = lazy(() => import('./Dashboard'));

export const DashboardManagerConfig = [
  {
    path: '/dashboard/home',
    element: <Dashboard />,
  },
];
