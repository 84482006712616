import React, {useContext} from 'react';
import {Link, useLocation} from 'react-router-dom';

import {AuthContext} from 'contexts/AuthContextProvider/authContext';
import {useSidebarContext} from '../../contexts/AppContextProvider/SidebarContextProvider';

function getStyles(item, sidebarColorSet) {
  const {pathname} = useLocation();
  const isActive = pathname.includes(item.id);

  return {
    color: isActive
      ? sidebarColorSet.sidebarMenuSelectedTextColor
      : sidebarColorSet.sidebarTextColor,
    backgroundColor: isActive
      ? sidebarColorSet.sidebarMenuSelectedBgColor
      : sidebarColorSet.sidebarBgColor,
  };
}

const renderTitle = (item) => {
  const {pathname} = useLocation();
  const isActive = pathname.includes(item.id);
  const {icon, title, path} = item;

  if (path.includes('/'))
    return (
      <Link to={path}>
        {React.isValidElement(icon) && (
          <span
            className={`ant-menu-item-icon ${
              isActive ? 'ant-menu-item-active' : null
            }`}>
            {icon}
          </span>
        )}
        <span data-testid={title.toLowerCase + '-nav'}>{title}</span>
      </Link>
    );
  else {
    return (
      <span>
        {React.isValidElement(icon) && (
          <span className='ant-menu-item-icon'>{icon}</span>
        )}
        <span data-testid={title.toLowerCase + '-nav'}>{title}</span>
      </span>
    );
  }
};

const renderMenu = (item, sidebarColorSet) => {
  return {
    key: item.id,
    label: renderTitle(item, 0),
    style: getStyles(item, sidebarColorSet),
  };
};

export const getRouteMenus = () => {
  const {routesConfig} = useContext(AuthContext);
  const {sidebarColorSet} = useSidebarContext();
  return routesConfig.map((route) => renderMenu(route, sidebarColorSet));
};
