import AccessProtected from 'lib/RouteProtection';
import {lazy} from 'react';

const ProductList = lazy(() => import('./ProductList'));
const EditSKU = lazy(() => import('./EditSKU'));

export const productManagerConfig = [
  {
    path: '/product-manager/product-list',
    element: (
      <AccessProtected>
        <ProductList />
      </AccessProtected>
    ),
  },
  {
    path: '/product-manager/edit-product/:id',
    element: <EditSKU />,
  },
];
