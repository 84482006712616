import React, {useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {Button, Col, Form, TreeSelect, Spin, Input} from 'antd';

import {AppRowContainer} from '@crema';
import AppCard from '@crema/core/AppCard';
import AppRowSimpleContainer from '@crema/core/AppRowContainer/AppRowSimpleContainer';
import AppInputField from 'components/AppComponents/AppInputField';
import AppLabel from 'components/AppComponents/AppLabel';
import AppPageHeader from 'components/AppComponents/AppPageHeader';
import AppPageWrapper from 'components/AppComponents/AppPageWrapper';
import UploadAvatar from 'components/FFManagerComponent/Common/UploadAvatar';
import {phoneNumberValidationRegex} from 'utils/Validations';

import {getAgency, updateAgency as updateAgencyApi} from 'api/AgencyManager';
import {getResources} from 'api/RoleManager';

const {SHOW_ALL} = TreeSelect;
const {TextArea} = Input;

const UpdateAgency = () => {
  const {t} = useTranslation();
  const {id} = useParams();
  const [form] = Form.useForm();

  const {data: resourceList} = getResources();
  const {data: agency} = getAgency(id);
  const {mutate: mutateUpdateAgency, isLoading: isAgencyUpdating} =
    updateAgencyApi();

  useEffect(() => {
    return () => localStorage.removeItem('agency-image');
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      name: agency.name,
      tag: agency.tag,
      description: agency.description,
      address: agency.address,
      email: agency.email,
      contact_person: agency.contact_person,
      phone_number: agency.phone_number,
      resources: agency.resources,
    });
    localStorage.setItem('agency-image', agency.image);
  }, [agency]);

  const breadCrumbRoutes = [
    {
      link: '/agency-manager/agency-list',
      title: t('int-affiliates'),
    },
    {
      link: null,
      title: t('int-update-affiliate'),
    },
  ];

  const updateAgency = (values) => {
    const image = localStorage.getItem('agency-image');
    if (image) {
      values.image = image;
    }

    const isResObj = values.resources.every((r) => typeof r === 'object');

    if (isResObj) {
      const resources = [];
      values.resources.map((el) => resources.push(el.value));
      values.resources = resources;
    }
    values.agency_id = id;
    delete values.tag;
    for (const key in values) {
      if (values[key] === undefined || values[key] === null) {
        delete values[key];
      }
    }
    mutateUpdateAgency(values);
  };

  return (
    <Spin spinning={isAgencyUpdating}>
      <AppPageHeader
        title={t('int-update-affiliate')}
        routeInfo={breadCrumbRoutes}
      />
      <AppPageWrapper>
        <Form onFinish={updateAgency} form={form}>
          <AppRowSimpleContainer key='update-agency'>
            <Col xs={12} md={6} lg={5} xl={4}>
              <UploadAvatar
                title={t('int-upload-logo')}
                name='Images-Agencies'
                imageLink='agency-image'
              />
            </Col>
            <Col xs={24} md={18} lg={19} xl={20}>
              <AppCard
                title={t('int-update-affiliate')}
                style={{marginBottom: '34px'}}>
                <AppRowSimpleContainer>
                  <Col xs={24} sm={10} lg={8} className='mt-1'>
                    <AppLabel required>{t('int-affiliate-name')}</AppLabel>
                  </Col>
                  <Col xs={24} sm={14} lg={16}>
                    <Form.Item
                      name='name'
                      rules={[
                        {
                          required: true,
                          message: t('int-plc-affiliate'),
                        },
                      ]}>
                      <AppInputField placeholder={t('int-plc-agency-name')} />
                    </Form.Item>
                  </Col>
                </AppRowSimpleContainer>
                <AppRowSimpleContainer>
                  <Col xs={24} sm={10} lg={8} className='mt-1'>
                    <AppLabel>{t('int-tag')}</AppLabel>
                  </Col>
                  <Col xs={24} sm={14} lg={16}>
                    <Form.Item name='tag'>
                      <AppInputField disabled placeholder={t('int-plc-tag')} />
                    </Form.Item>
                  </Col>
                </AppRowSimpleContainer>
                <AppRowSimpleContainer>
                  <Col xs={24} sm={10} lg={8} className='mt-1'>
                    <AppLabel>{t('int-description')}</AppLabel>
                  </Col>
                  <Col xs={24} sm={14} lg={16}>
                    <Form.Item name='description'>
                      <TextArea
                        placeholder={t('int-please-enter-description')}
                      />
                    </Form.Item>
                  </Col>
                </AppRowSimpleContainer>
                <AppRowSimpleContainer>
                  <Col xs={24} sm={10} lg={8} className='mt-1'>
                    <AppLabel>{t('int-address')}</AppLabel>
                  </Col>
                  <Col xs={24} sm={14} lg={16}>
                    <Form.Item name='address'>
                      <AppInputField placeholder={t('int-plc-address')} />
                    </Form.Item>
                  </Col>
                </AppRowSimpleContainer>
                <AppRowSimpleContainer>
                  <Col xs={24} sm={10} lg={8} className='mt-1'>
                    <AppLabel>{t('int-email')}</AppLabel>
                  </Col>
                  <Col xs={24} sm={14} lg={16}>
                    <Form.Item
                      name='email'
                      rules={[
                        {
                          type: 'email',
                          message: t('int-email-not-valid'),
                        },
                      ]}>
                      <AppInputField placeholder={t('int-plc-email')} />
                    </Form.Item>
                  </Col>
                </AppRowSimpleContainer>
                <AppRowSimpleContainer>
                  <Col xs={24} sm={10} lg={8} className='mt-1'>
                    <AppLabel>{t('int-contact-person')}</AppLabel>
                  </Col>
                  <Col xs={24} sm={14} lg={16}>
                    <Form.Item name='contact_person'>
                      <AppInputField
                        placeholder={t('int-plc-contact-person')}
                      />
                    </Form.Item>
                  </Col>
                </AppRowSimpleContainer>
                <AppRowSimpleContainer>
                  <Col xs={24} sm={10} lg={8} className='mt-1'>
                    <AppLabel>{t('int-official-phone-number')}</AppLabel>
                  </Col>
                  <Col xs={24} sm={14} lg={16}>
                    <Form.Item
                      name='phone_number'
                      rules={[
                        {
                          pattern: phoneNumberValidationRegex,
                          message: t('int-phone-not-valid'),
                        },
                      ]}>
                      <AppInputField placeholder={t('int-plc-phone-number')} />
                    </Form.Item>
                  </Col>
                </AppRowSimpleContainer>
                <AppRowSimpleContainer>
                  <Col xs={24} sm={10} lg={8} className='mt-1'>
                    <AppLabel required>{t('int-resource-list')}</AppLabel>
                  </Col>
                  <Col xs={24} sm={14} lg={16}>
                    <Form.Item
                      name='resources'
                      rules={[
                        {required: true, message: t('int-resource-required')},
                      ]}>
                      <TreeSelect
                        showSearch
                        style={{
                          width: '100%',
                        }}
                        multiple
                        placeholder={t('int-plc-resource')}
                        allowClear
                        treeData={resourceList}
                        treeCheckable={true}
                        showCheckedStrategy={SHOW_ALL}
                        treeCheckStrictly
                        treeDefaultExpandAll
                        maxTagCount={3}
                      />
                    </Form.Item>
                  </Col>
                </AppRowSimpleContainer>
                <AppRowContainer>
                  <Col xs={24} sm={10} lg={8} className='mt-1'></Col>
                  <Col xs={24} sm={14} lg={16}>
                    <Button type='primary' htmlType='submit'>
                      {t('int-update')}
                    </Button>
                  </Col>
                </AppRowContainer>
              </AppCard>
            </Col>
          </AppRowSimpleContainer>
        </Form>
      </AppPageWrapper>
    </Spin>
  );
};

export default UpdateAgency;
